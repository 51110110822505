import { MAINTAIN_PERMISSION, DELETE_PERMISSION, GROWERS_GONDOLALOG } from "@kraftheinz/common";
import { n as normalizeComponent } from "./vueComponentNormalizer.js";
var render$1 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", [_c("list-composer", {
    attrs: {
      "title": "Gondola Log",
      "search-by": "GondolaCode,TubCode,PaddockCode",
      "ph-search-by": "Search by Code, Tub, Paddock"
    },
    scopedSlots: _vm._u([{
      key: "action",
      fn: function(_ref) {
        var edit = _ref.edit, record = _ref.record, toggleActive = _ref.toggleActive;
        return [_vm.hasDeletePermission ? _c("a-tooltip", {
          scopedSlots: _vm._u([{
            key: "title",
            fn: function() {
              return [_vm._v(_vm._s(record.isInactive ? "Activate" : "Deactivate"))];
            },
            proxy: true
          }], null, true)
        }, [_c("a-button", {
          staticClass: "list__action-toggle-active mr-2",
          attrs: {
            "icon": record.isInactive ? "eye-invisible" : "eye",
            "size": "small",
            "type": "link"
          },
          on: {
            "click": function($event) {
              return toggleActive(record);
            }
          }
        })], 1) : _vm._e(), _vm.hasMaintainPermission ? _c("a-tooltip", {
          scopedSlots: _vm._u([{
            key: "title",
            fn: function() {
              return [_vm._v("Edit")];
            },
            proxy: true
          }], null, true)
        }, [_c("a-button", {
          attrs: {
            "icon": "edit",
            "size": "small",
            "type": "link"
          },
          on: {
            "click": function($event) {
              return edit(record);
            }
          }
        })], 1) : _vm._e()];
      }
    }])
  }, [_c("text-field", {
    key: "Id",
    attrs: {
      "data-index": "id",
      "sorter": true,
      "title": "LogID"
    }
  }), _c("text-field", {
    key: "GondolaCode",
    attrs: {
      "data-index": "gondolaCode",
      "sorter": true,
      "title": "Gondola Code"
    }
  }), _c("text-field", {
    key: "TubCode",
    attrs: {
      "data-index": "tubCode",
      "sorter": true,
      "title": "Tub"
    }
  }), _c("text-field", {
    key: "PaddockCode",
    attrs: {
      "data-index": "paddockCode",
      "sorter": true,
      "title": "Paddock Code"
    }
  }), _c("text-field", {
    key: "Harvester",
    attrs: {
      "data-index": "harvester",
      "sorter": true,
      "title": "Harvester"
    }
  }), _c("text-field", {
    key: "TimeCalled",
    attrs: {
      "data-index": "timeCalled",
      "sorter": true,
      "title": "Time Called",
      "parse": _vm.parseTimeCalled
    }
  }), _c("text-field", {
    key: "Disposition",
    attrs: {
      "data-index": "disposition",
      "sorter": true,
      "title": "Disposition"
    }
  }), _c("icon-field", {
    key: "RtCalled",
    attrs: {
      "data-index": "rtCalled",
      "title": "RT Called",
      "align": "center",
      "width": 120,
      "render-icon": _vm.renderIcon
    }
  })], 1)], 1);
};
var staticRenderFns$1 = [];
const __vue2_script$1 = {
  name: "GondolaLog",
  inject: ["can"],
  computed: {
    hasMaintainPermission() {
      return this.can(MAINTAIN_PERMISSION);
    },
    hasDeletePermission() {
      return this.can(DELETE_PERMISSION);
    }
  },
  methods: {
    renderIcon(value) {
      if (!value) {
        return "";
      }
      return "check-circle";
    },
    parseTimeCalled(value) {
      return this.$moment(value).format("D/M/Y - h:mm:ss A");
    }
  }
};
const __cssModules$1 = {};
var __component__$1 = /* @__PURE__ */ normalizeComponent(__vue2_script$1, render$1, staticRenderFns$1, false, __vue2_injectStyles$1, null, null, null);
function __vue2_injectStyles$1(context) {
  for (let o in __cssModules$1) {
    this[o] = __cssModules$1[o];
  }
}
var GondolaLog = /* @__PURE__ */ function() {
  return __component__$1.exports;
}();
var render = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", [_c("bread-crumb", {
    attrs: {
      "items": _vm.itemsMenu
    }
  }), _c("resource", {
    attrs: {
      "name": "grower.gondola-logs",
      "api-url": _vm.apiUrl,
      "create-route": "gondola-log/create",
      "edit-route": "gondola-log/:id",
      "page": _vm.page
    }
  }, [_c("gondola-log")], 1), _c("router-view")], 1);
};
var staticRenderFns = [];
const apiUrl = "#{VUE_APP_API_URL}#";
const __vue2_script = {
  components: {
    GondolaLog
  },
  data() {
    return {
      apiUrl,
      itemsMenu: [
        {
          key: "harvestprocessing",
          title: "Harvest Processing",
          path: ""
        },
        {
          key: "Sample-types",
          title: "Gondola Log",
          path: "/watties-grower/gondola-log"
        }
      ],
      page: GROWERS_GONDOLALOG
    };
  }
};
const __cssModules = {};
var __component__ = /* @__PURE__ */ normalizeComponent(__vue2_script, render, staticRenderFns, false, __vue2_injectStyles, null, null, null);
function __vue2_injectStyles(context) {
  for (let o in __cssModules) {
    this[o] = __cssModules[o];
  }
}
var index = /* @__PURE__ */ function() {
  return __component__.exports;
}();
export { index as default };
